import React, { useEffect, useState } from 'react'
import { Typography } from '@copa/design-system-factory.typography'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { theme } from '@copa/design-system-factory.theme'
import VisaIcon from '../../../../assets/Icons/creditcards/Visa'
import AmericanExpress from '../../../../assets/Icons/creditcards/AmericanExpress'
import DinnersClub from '../../../../assets/Icons/creditcards/DinnersClub'
import Discover from '../../../../assets/Icons/creditcards/Discover'
import MasterCard from '../../../../assets/Icons/creditcards/MasterCard'
import Uatp from '../../../../assets/Icons/creditcards/Uatp'

function CreditCardDetail({ creditCardDetails }) {
  const [cardNumber, setCardNumber] = useState('')
  const [cardType, setCardType] = useState('')

  useEffect(() => {
    if (creditCardDetails) {
      const details = creditCardDetails.split('/')
      setCardType(details[0])
      setCardNumber(details[1])
    }
  }, [creditCardDetails])

  const creditCards = {
    VI: { name: 'Visa', icon: <VisaIcon /> },
    AX: { name: 'American Express', icon: <AmericanExpress /> },
    DC: { name: 'Diners Club', icon: <DinnersClub /> },
    DS: { name: 'Discover', icon: <Discover /> },
    CA: { name: 'MasterCard', icon: <MasterCard /> },
    CAD: { name: 'MasterCard Debit', icon: <MasterCard /> },
    TP: { name: 'UATP', icon: <Uatp /> },
    VID: { name: 'Visa Debit', icon: <VisaIcon /> },
    EMD: { name: 'EMD' },
  }

  return (
    <Box
      id="Create_Refund_Card"
      sx={{
        maxWidth: '496px',
        height: '64px',
        borderRadius: '8px',
        padding: '20px',
        border: `1px solid ${theme.palette.grey[200]}`,
        margin: '24px 0 34px 0',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {creditCards[cardType]?.icon}
      <Typography
        style={{
          marginLeft: '16px',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
          color: theme.palette.grey[800],
        }}
        sx={{ fontFamily: 'SuisseIntl' }}
      >
        {`${creditCards[cardType]?.name ?? cardType} ****${cardNumber}`}
      </Typography>
    </Box>
  )
}

CreditCardDetail.propTypes = {
  creditCardDetails: PropTypes.string,
}

CreditCardDetail.defaultProps = {
  creditCardDetails: '',
}

export default CreditCardDetail
