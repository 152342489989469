import React from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { Typography } from '@copa/design-system-factory.typography'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

function CreditCardDisclaimer({ message }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <InfoIcon sx={{ marginRight: '10px' }} color="primary" />
      <Typography
        style={{
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '20px',
        }}
      >
        {message}
      </Typography>
    </Box>
  )
}

CreditCardDisclaimer.propTypes = {
  message: PropTypes.string.isRequired,
}

export default CreditCardDisclaimer
